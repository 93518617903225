import * as React from 'react';
import { Alert, Button, Paper, Stack, TextField, Typography } from '@mui/material';
import { useApiService } from '../service/api';
import { useNavigate } from 'react-router-dom';

const Search = () => {
    const api = useApiService();
    const navigate = useNavigate();
    const [input, setInput] = React.useState<string>('');
    const [showError, setShowError] = React.useState<boolean>(false);

    const handleSubmit = async () => {
        if (!input) return;
        try {
            await api.get(`deliveryboxes/${input}`);
            navigate(`/deliverybox/${input}`);
        } catch (e) {
            setShowError(true);
        }
    };

    return (
        <Paper sx={{ p: 2 }}>
            <Typography variant='h2' children='Search for Deliveries' />
            <Stack direction='row' gap={2}>
                <TextField fullWidth value={input} onChange={e => setInput(e.target.value)} />
                <Button onClick={handleSubmit} children='Search' />
            </Stack>
            {showError && <Alert severity='error' children='Delivery not found, please check your entries and try again!' sx={{ mt: 2 }} onClose={() => setShowError(false)} />}
        </Paper>
    );
}
export default Search;
