import * as React from 'react';

import { SecurityContext } from './Security';
import AuthService from './AuthService';
import { useLocation } from 'react-router-dom';

import Login from '../pages/Login';
import AppBar from '../components/AppBar';

interface SecurityProviderProps {
    children: React.ReactNode,
}

const SecurityProvider = ({ children }: SecurityProviderProps) => {
    const [loggedIn, setLoggedIn] = React.useState(true);
    const { pathname } = useLocation();
    const auth = AuthService;

    React.useEffect(() => {
        setLoggedIn(true);
    },[pathname]);

    return (
        <SecurityContext.Provider
            value={{
                login: async (username: string, password: string) => {
                    const result = await auth.login(username, password);
                    if (result) setLoggedIn(result);
                    return result;
                },
                register: async (email: string, username: string, password: string) => {
                    const result = await auth.register(email, username, password);
                    // setLoggedIn(result);
                    return result;
                },
                logout: async () => {
                    await auth.logout();
                    setLoggedIn(false);
                },
                onFailure() {
                    setLoggedIn(false);
                    return;
                },
                loggedIn,
            }}
        >
            <AppBar />
            {loggedIn ? children : <Login />}
        </SecurityContext.Provider>
    );
}
export default SecurityProvider;
