import * as React from 'react';
import { Paper, Skeleton, Typography } from '@mui/material';
import { useApiService } from '../service/api';
import { OrderData, UserData } from '../components/Types';
import OrderTable from '../components/OrderTable';

const Deliverer = () => {
    const api = useApiService();
    const [user, setUser] = React.useState<UserData>();
    const [deliveryData, setDeliveryData] = React.useState<OrderData[]>();
    const [update, setUpdate] = React.useState<number>(0);

    React.useEffect(() => {
        async function fetchUserData() {
            return await api.get(`auth/users/${userId}`);
        }
        async function fetchDeliveryData() {
            return await api.get(`api/orders/deliveryperson/${userId}`);
        }
        const userId = localStorage.getItem("userId");

        api.verifyPermission(2).then(access => {
            if (!access) return;
            fetchUserData().then(res => setUser(res.data));
            fetchDeliveryData().then(res => setDeliveryData(res.data));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update]);

    const pickUpOrder = (id: string) => {
        api.patch(`orders/${id}/deliveryperson`, { status: "IN_DELIVERY" }).then(() => {
            setDeliveryData(undefined);
            setUpdate(update + 1);
        });
    };

    return (
        user ? (
            <Paper sx={{ p: 2 }}>
                <Typography>Hello {user.username}!</Typography>
                <Typography>Your email: {user.email}.</Typography>
                <Typography>This is your customer-id: {user.id}.</Typography>
                <Typography variant='h6' sx={{ mt: 2 }}>Below is a list of all orders, you were assigned to</Typography>
                {deliveryData ? <OrderTable isDispatcher data={deliveryData} pickUpOrder={pickUpOrder} /> : <Skeleton variant='rectangular' sx={{ height: '400px' }} />}
            </Paper>
        ) : (
            <h3>Error</h3>
        )
    );
}
export default Deliverer;
