export interface OrderData {
    id: string,
    createdAt: string,
    deliveredAt: string,
    updatedAt: string,
    orderedBy: UserData,
    deliveryPerson: UserData,
    destinationBox: DeliveryBoxData,
    status: string,
}

export interface DeliveryBoxData {
    id: string,
    empty: boolean,
    address: string,
}

export interface UserData {
    id: string,
    username: string,
    email: string,
    role: string,
}

export const orderStatusType = new Map<string, string>([
    ["WAITING", "Ready to deliver"],
    ["IN_DELIVERY", "In delivery"],
    ["DELIVERED", "Ready to be picked up"],
]);

export const userRoleType = new Map<string, string>([
    ["DISPATCHER", "Dispatcher"],
    ["DELIVERY_PERSON", "Deliverer"],
    ["CUSTOMER", "Customer"],
    ["ADMIN", "Admin"],
]);

export enum userRoleLevel {
    "CUSTOMER" = 1,
    "DELIVERY_PERSON",
    "DISPATCHER",
    "ADMIN"
}
