import * as React from 'react';
import { Paper, Skeleton, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useApiService } from '../service/api';
import { OrderData, UserData, userRoleType } from '../components/Types';
import OrderTable from '../components/OrderTable';


const UserView = () => {
    const params = useParams();
    const api = useApiService();

    const [user, setUser] = React.useState<UserData>();
    const [orders, setOrders] = React.useState<OrderData[]>();
    const [deliveryData, setDeliveryData] = React.useState<OrderData[]>();

    React.useEffect(() => {
        async function fetchUserData() {
            return await api.get(`users/${params.id}`);
        }
        async function fetchOrderData() {
            return await api.get(`orders/${params.id}/customer`);
        }
        async function fetchDeliveryData() {
            return await api.get(`orders/deliveryperson/${params.id}`);
        }

        api.verifyPermission(2).then(access => {
            if (!access) return;
            fetchUserData().then(result => {
                setUser(result.data);
                if (result.data.role === 'CUSTOMER') {
                    fetchOrderData().then(res => setOrders(res.data));
                } else if (result.data.role === 'DELIVERY_PERSON') {
                    fetchDeliveryData().then(res => setDeliveryData(res.data));
                }
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    const customer = <>
        <Typography variant='h6' children={`All Orders from ${user?.username}`} sx={{ pt: 2 }} />
        {orders ? <OrderTable data={orders} /> : <Skeleton variant='rectangular' sx={{ height: '400px' }} />}
    </>;

    const deliverer = <>
        <Typography variant='h6' children={`All Orders assigned to ${user?.username}`} sx={{ pt: 2 }} />
        {deliveryData ? <OrderTable data={deliveryData} /> : <Skeleton variant='rectangular' sx={{ height: '400px' }} />}
    </>;

    return (
        <Paper sx={{ p: 2 }}>
            <Typography variant='h6'>
                User with id {params.id}
            </Typography>
            {user && (
                <Typography>
                    Their username is <strong>{user.username}</strong>, the email is <strong>{user.email}</strong> and their user role is <strong>{userRoleType.get(user.role)}</strong>.
                </Typography>
            )}
            {user?.role === 'CUSTOMER' && customer}
            {user?.role === 'DELIVERY_PERSON' && deliverer}
        </Paper>
    );
}
export default UserView;
