import * as React from 'react';
import { Alert, Button, Paper, Stack, TextField, Typography } from '@mui/material';
import { useApiService } from '../service/api';
import { Link, useLocation, useParams } from 'react-router-dom';

const CreateBox = () => {
    const api = useApiService();
    const { pathname } = useLocation();
    const params = useParams();
    const [isEditMode, setEditMode] = React.useState<boolean>(false);
    const [address, setAddress] = React.useState<string>('');
    const [piId, setPiId] = React.useState<string>('');
    const [showSuccess, setShowSuccess] = React.useState<boolean>(false);
    const [showFailure, setShowFailure] = React.useState<boolean>(false);

    React.useEffect(() => {
        async function fetchBoxData() {
            return await api.get(`api/deliveryboxes/${params.id}`);
        }

        api.verifyPermission(3).then(access => {
            if (!access) return;
            if (pathname.includes('edit')) {
                setEditMode(true);
                fetchBoxData().then(res => {
                    setAddress(res.data.email);
                    setPiId(res.data.piPiId)
                });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = async () => {
        const payload = {
            address: address,
            piId: piId,
        };

        try {
            if (!isEditMode) {
                await api.post('api/deliveryboxes/admin', payload);
            } else {
                await api.patch(`api/deliveryboxes/${params.id}/admin`, payload);
            }

            setShowSuccess(true);
            setShowFailure(false);
        } catch (e) {
            setShowFailure(true);
            setShowSuccess(false);
        }
    };

    return (
        <Paper sx={{ p: 2 }}>
            <Typography variant='h4' children={isEditMode ? `Edit Delivery Box with id ${params.id}` : 'Create Delivery Box'} sx={{ mb: 2 }} />
            <Stack spacing={2}>
                <TextField required label='Address' value={address} onChange={e => setAddress(e.target.value)} sx={{ width: '300px' }} />
                <TextField required label='Raspberry Pi Id' value={piId} onChange={e => setPiId(e.target.value)} hidden sx={{ width: '300px' }} />
                <Button variant='contained' color='success' children='Save' onClick={handleSubmit} sx={{ width: '300px' }} />
                {showSuccess && (<Alert onClose={() => setShowSuccess(false)}>
                    <>
                        {`${isEditMode ? 'Edited' : 'Created'} successfully! `}
                        {isEditMode && <Link to={`/deliverybox/${params.id}`}>Go back to User</Link>}
                        {isEditMode || <Link to='/dispatcher'>Go back to List</Link>}
                    </>
                </Alert>)}
                {showFailure && <Alert severity='error' children='There has been an Error, please check your entries and try again' onClose={() => setShowFailure(false)} />}
            </Stack>
        </Paper>
    );
}
export default CreateBox;
