import * as React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';

import Login from './pages/Login';
import Home from './pages/Home';
import Test from './pages/Test';
import Signup from './pages/Signup';
import SecurityProvider from './service/SecurityProvider';
import Customer from './pages/Customer';
import DeliveryBox from './pages/DeliveryBox';
import Order from './pages/Order';
import Dispatcher from './pages/Dispatcher';
import UserView from './pages/UserView';
import Deliverer from './pages/Deliverer';
import AccessDenied from './pages/AccessDenied';
import CreateOrder from './pages/CreateOrder';
import CreateUser from './pages/CreateUser';
import CreateBox from './pages/CreateBox';
import Search from './pages/Search';

function App() {
    return (
        <div className='App'>
            <BrowserRouter>
                <SecurityProvider>
                    <Routes>
                        <Route path='/' element={<Home />} />
                        <Route path='access-denied' element={<AccessDenied />} />
                        <Route path='login' element={<Login />} />
                        <Route path='signup' element={<Signup />} />
                        <Route path='secret' element={<Test />} />
                        <Route path='search' element={<Search />} />
                        <Route path='customeroverview' element={<Customer />} />
                        <Route path='deliverer' element={<Deliverer />} />
                        <Route path='dispatcher' element={<Dispatcher />} />
                        <Route path='user'>
                            <Route path='create' element={<CreateUser />} />
                            <Route path='edit' >
                                <Route path=':id' element={<CreateUser />} />
                            </Route>
                            <Route path=':id' element={<UserView />} />
                        </Route>
                        <Route path='deliverybox'>
                            <Route path='create' element={<CreateBox />} />
                            <Route path='edit'>
                                <Route path=':id' element={<CreateBox />} />
                            </Route>
                            <Route path=':id' element={<DeliveryBox />} />
                        </Route>
                        <Route path='order'>
                            <Route path='create' element={<CreateOrder />} />
                            <Route path='edit'>
                                <Route path=':id' element={<CreateOrder />} />
                            </Route>
                            <Route path=':id' element={<Order />} />
                        </Route>
                    </Routes>
                </SecurityProvider>
            </BrowserRouter>
        </div>
    );
}

export default App;
