import * as React from 'react';

export type Context = {
    login: (email: string, password: string) => Promise<boolean>,
    register: (email: string, username: string, password: string) => Promise<boolean>,
    logout: () => Promise<void>,
    onFailure: () => void,
    loggedIn: boolean
}

export const SecurityContext = React.createContext<Context>({} as Context);

export const useSecurity = () => React.useContext(SecurityContext);
