import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Paper, Skeleton, Typography } from '@mui/material';
import OrderTable from '../components/OrderTable';
import { DeliveryBoxData, OrderData, UserData } from '../components/Types';
import { useApiService } from '../service/api';
import BoxTable from '../components/BoxTable';
import UserTable from '../components/UserTable';


const Dispatcher = () => {
    const api = useApiService();
    const navigate = useNavigate();
    const [orders, setOrders] = React.useState<OrderData[]>();
    const [boxes, setBoxes] = React.useState<DeliveryBoxData[]>();
    const [customers, setCustomers] = React.useState<UserData[]>();
    const [deliverers, setDeliverers] = React.useState<UserData[]>();
    const [update, setUpdate] = React.useState<number>(0);

    React.useEffect(() => {
        async function fetchOrderData() {
            return await api.get('orders/admin');
        }
        async function fetchBoxData() {
            return await api.get('deliveryboxes');
        }
        async function fetchUserData() {
            return await api.get('users');
        }

        api.verifyPermission(3).then(access => {
            if (!access) return;
            fetchOrderData().then(res => setOrders(res.data));
            fetchBoxData().then(res => setBoxes(res.data));
            fetchUserData().then(res => {
                setCustomers(res.data.filter((x: UserData) => x.role === 'CUSTOMER') || []);
                setDeliverers(res.data.filter((x: UserData) => x.role === 'DELIVERY_PERSON') || []);
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update]);

    const handleOrderDelete = (id: string) => {
        setOrders(undefined);
        setUpdate(update + 1);
        /*api.delete_(`orders/${id}/admin`).then(() => {
            setOrders(undefined);
            setUpdate(update+1);
        })*/
    };

    const handleBoxDelete = (id: string) => {
        api.delete_(`deliveryboxes/${id}/admin`).then(() => {
            setBoxes(undefined);
            setUpdate(update + 1);
        })
    };

    const handleCustomerDelete = (id: string) => {
        api.delete_(`users/${id}/admin`).then(() => {
            setCustomers(undefined);
            setUpdate(update + 1);
        })
    };

    const handleDelivererDelete = (id: string) => {
        api.delete_(`users/${id}/admin`).then(() => {
            setDeliverers(undefined);
            setUpdate(update + 1);
        })
    };

    const pickUpOrder = (id: string) => {
        api.patch(`orders/${id}/deliveryperson`, { status: "IN_DELIVERY" }).then(() => {
            setOrders(undefined);
            setUpdate(update + 1);
        });
    };

    const handleEditOrder = (id: string) => {
        navigate(`/order/edit/${id}`);
    };

    const handleEditUser = (id: string) => {
        navigate(`/user/edit/${id}`);
    };

    const handleEditBox = (id: string) => {
        navigate(`/deliverybox/edit/${id}`);
    };

    return (
        <Paper sx={{ p: 2 }}>
            <Typography variant='h2' children='Dispatcher Control Panel' />
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px' }}>
                <Typography variant='h6' children='All Orders' sx={{ pt: 2 }} />
                <Button variant='contained' color='success' children='Create New' onClick={() => navigate('/order/create')} />
            </div>
            {orders ? <OrderTable isDispatcher data={orders} handleDelete={handleOrderDelete} pickUpOrder={pickUpOrder} handleEdit={handleEditOrder} /> : <Skeleton variant='rectangular' sx={{ height: '400px' }} />}
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px' }}>
                <Typography variant='h6' children='All Delivery Boxes' sx={{ pt: 2 }} />
                <Button variant='contained' color='success' children='Create New' onClick={() => navigate('/deliverybox/create')} />
            </div>
            {boxes ? <BoxTable data={boxes} handleDelete={handleBoxDelete} handleEdit={handleEditBox} /> : <Skeleton variant='rectangular' sx={{ height: '400px' }} />}
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px' }}>
                <Typography variant='h6' children='All Customers' sx={{ pt: 2 }} />
                <Button variant='contained' color='success' children='Create New User' onClick={() => navigate('/user/create')} />
            </div>
            {customers ? <UserTable data={customers} handleDelete={handleCustomerDelete} handleEdit={handleEditUser} /> : <Skeleton variant='rectangular' sx={{ height: '400px' }} />}
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px' }}>
                <Typography variant='h6' children='All Deliverers' sx={{ pt: 2 }} />
                <Button variant='contained' color='success' children='Create New User' onClick={() => navigate('/user/create')} />
            </div>
            {deliverers ? <UserTable data={deliverers} handleDelete={handleDelivererDelete} handleEdit={handleEditUser} /> : <Skeleton variant='rectangular' sx={{ height: '400px' }} />}
        </Paper>
    );
}
export default Dispatcher;
