import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useSecurity } from './Security';

export const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
});

/**
 * Use the provided functions to make API calls. If the user is unauthorized, these functions handle security flow
 * @returns 
 */
export function useApiService() {
    const security = useSecurity();

    async function get<D = any>(url: string, config: AxiosRequestConfig<D> = {}): Promise<AxiosResponse<any, any>> {
        try {
            const response = await api.get(url, config);
            return response;
        } catch (error: any) {
            if (error.response?.status === 403) {
                security.onFailure();
            }
            return Promise.reject();
        }
    }

    /**
     * HTTP Delete request. Named delete_ because of reserved word
     * @param url 
     * @param config 
     */
    async function delete_<D = any>(url: string, config: AxiosRequestConfig<D> = {}): Promise<AxiosResponse<any, any>> {
        try {
            const response = await api.delete(url, config);
            return response;
        } catch (error: any) {
            if (error.response?.status === 403) {
                security.onFailure();
            }
            return Promise.reject();
        }
    }

    async function head<D = any>(url: string, config: AxiosRequestConfig<D> = {}): Promise<AxiosResponse<any, any>> {
        try {
            const response = await api.head(url, config);
            return response;
        } catch (error: any) {
            if (error.response?.status === 403) {
                security.onFailure();
            }
            return Promise.reject();
        }
    }

    async function options<D = any>(url: string, config: AxiosRequestConfig<D> = {}): Promise<AxiosResponse<any, any>> {
        try {
            const response = await api.options(url, config);
            return response;
        } catch (error: any) {
            if (error.response?.status === 403) {
                security.onFailure();
            }
            return Promise.reject();
        }
    }

    async function post<D = any>(url: string, data?: D, config: AxiosRequestConfig<D> = {}): Promise<AxiosResponse<any, any>> {
        try {
            const response = await api.post(url, data, config);
            return response;
        } catch (error: any) {
            if (error.response?.status === 403) {
                security.onFailure();
            }
            return Promise.reject();
        }
    }

    async function put<D = any>(url: string, data?: D, config: AxiosRequestConfig<D> = {}): Promise<AxiosResponse<any, any>> {
        try {
            const response = await api.put(url, data, config);
            return response;
        } catch (error: any) {
            if (error.response?.status === 403) {
                security.onFailure();
            }
            return Promise.reject();
        }
    }

    async function patch<D = any>(url: string, data?: D, config: AxiosRequestConfig<D> = {}): Promise<AxiosResponse<any, any>> {
        try {
            const response = await api.patch(url, data, config);
            return response;
        } catch (error: any) {
            if (error.response?.status === 403) {
                security.onFailure();
            }
            return Promise.reject();
        }
    }

    /**
     * Helper-function that checks if current user has enough permission to display the current site.
     * Redirects to /access-denied if not, returns true if yes
     * @param level required level of permission: 0 = none, 1 = Customer, 2 = Deliverer, 3 = Dispatcher, 4 = Admin
     */
    async function verifyPermission(level: number) {
        // let apiRole = 0;
        /*const role: string = (await get('users/role')).data;
        if (role in userRoleLevel) {
            apiRole = (userRoleLevel as any)[role];
        }

        return (apiRole >= level);
        */
       return true;
    }

    return { get, delete_, head, options, post, put, patch, verifyPermission };
}
