import * as React from 'react';
import { Alert, Button, FormControl, InputLabel, MenuItem, Paper, Select, Stack, Typography } from '@mui/material';
import { useApiService } from '../service/api';
import { DeliveryBoxData, UserData } from '../components/Types';
import { Link, useLocation, useParams } from 'react-router-dom';

const CreateOrder = () => {
    const api = useApiService();
    const { pathname } = useLocation();
    const params = useParams();
    const [isEditMode, setEditMode] = React.useState<boolean>(false);
    const [boxes, setBoxes] = React.useState<DeliveryBoxData[]>();
    const [selectedBox, setSelectedBox] = React.useState<string>('');
    const [customers, setCustomers] = React.useState<UserData[]>();
    const [selectedCustomer, setSelectedCustomer] = React.useState<string>('');
    const [deliverers, setDeliverers] = React.useState<UserData[]>();
    const [selectedDeliverer, setSelectedDeliverer] = React.useState<string>('');
    const [showSuccess, setShowSuccess] = React.useState<boolean>(false);
    const [showFailure, setShowFailure] = React.useState<boolean>(false);


    React.useEffect(() => {
        async function fetchBoxData() {
            return await api.get('deliveryboxes');
        }
        async function fetchUserData() {
            return await api.get('users');
        }
        async function fetchOrderData() {
            return await api.get(`orders/${params.id}`);
        }

        api.verifyPermission(3).then(access => {
            if (!access) return;
            fetchBoxData().then(res => setBoxes(res.data));
            fetchUserData().then(res => {
                setCustomers(res.data.filter((x: UserData) => x.role === 'CUSTOMER') || []);
                setDeliverers(res.data.filter((x: UserData) => x.role === 'DELIVERY_PERSON') || []);
                if (pathname.includes('edit')) {
                    setEditMode(true);
                    fetchOrderData().then(result => {
                        setSelectedCustomer(result.data.orderedBy.id);
                        setSelectedBox(result.data.destinationBox.id);
                        setSelectedDeliverer(result.data.deliveryPerson.id);
                    })
                }
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = async () => {
        const payload = {
            orderedBy: {
                id: selectedCustomer
            },
            destinationBox: {
                id: selectedBox
            },
            deliveryPerson: {
                id: selectedDeliverer
            }
        };

        try {
            if (!isEditMode) {
                await api.post('orders', payload);
            } else {
                await api.patch(`orders/${params.id}/deliveryperson`, payload);
            }

            setShowSuccess(true);
            setShowFailure(false);
        } catch (e) {
            setShowFailure(true);
            setShowSuccess(false);
        }
    };

    return (
        <Paper sx={{ p: 2 }}>
            <Typography variant='h4' children={isEditMode ? `Edit Order with id ${params.id}` : 'Create a new Order'} sx={{ mb: 2 }} />
            <Stack spacing={2}>
                <FormControl>
                    <InputLabel children='Select Customer' />
                    <Select label='Select Customer' sx={{ width: '300px' }} value={selectedCustomer} onChange={v => setSelectedCustomer(v.target.value as string)}>
                        {customers?.map(c => <MenuItem value={c.id} children={c.username} key={c.id} />)}
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel children='Select Delivery Box' />
                    <Select label='Select Delivery Box' sx={{ width: '300px' }} value={selectedBox} onChange={v => setSelectedBox(v.target.value as string)}>
                        {boxes?.map(b => <MenuItem value={b.id} children={b.address} key={b.id} />)}
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel children='Select Deliverer' />
                    <Select label='Select Deliverer' sx={{ width: '300px' }} value={selectedDeliverer} onChange={v => setSelectedDeliverer(v.target.value as string)}>
                        {deliverers?.map(d => <MenuItem value={d.id} children={d.username} key={d.id} />)}
                    </Select>
                </FormControl>
                <Button variant='contained' color='success' children='Save' onClick={handleSubmit} sx={{ width: '300px' }} />
                {showSuccess && (<Alert onClose={() => setShowSuccess(false)}>
                    <>
                        {`${isEditMode ? 'Edited' : 'Created'} successfully! `}
                        {isEditMode && <Link to={`/order/${params.id}`}>Go back to Order</Link>}
                        {isEditMode || <Link to='/dispatcher'>Go back to List</Link>}
                    </>
                </Alert>)}
                {showFailure && <Alert color='error' children='There has been an Error, please check your entries and try again' onClose={() => setShowFailure(false)} />}
            </Stack>
        </Paper>
    );
}
export default CreateOrder;
