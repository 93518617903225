import * as React from 'react';
import { Alert, Button, FormControl, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography } from '@mui/material';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useApiService } from '../service/api';
import { userRoleType } from '../components/Types';

const userTypes = ["CUSTOMER", "DELIVERY_PERSON", "DISPATCHER", "ADMIN"];

const EMAIL_REGEX = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

const CreateUser = () => {
    const api = useApiService();
    const { pathname } = useLocation();
    const params = useParams();
    const [isEditMode, setEditMode] = React.useState<boolean>(false);
    const [userType, setUserType] = React.useState<string>('CUSTOMER');
    const [userName, setUserName] = React.useState<string>('');
    const [email, setEmail] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');
    const [token, setToken] = React.useState<string>('');
    const [showSuccess, setShowSuccess] = React.useState<boolean>(false);
    const [showFailure, setShowFailure] = React.useState<boolean>(false);

    React.useEffect(() => {
        async function fetchUserData() {
            return await api.get(`users/${params.id}`);
        }

        api.verifyPermission(3).then(access => {
            if (!access) return;
            if (pathname.includes('edit')) {
                setEditMode(true);
                fetchUserData().then(res => {
                    setEmail(res.data.email);
                    setUserName(res.data.username);
                    setUserType(res.data.role);
                    setToken(res.data.token || '');
                });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = async () => {
        const payload = {
            role: userType,
            username: userName,
            email: email,
            password: password,
            token: token,
        };

        try {
            if (!isEditMode) {
                await api.post('users', payload);
            } else {
                await api.patch(`users/${params.id}`, payload);
            }

            setShowSuccess(true);
            setShowFailure(false);
        } catch (e) {
            setShowFailure(true);
            setShowSuccess(false);
        }
    };

    return (
        <Paper sx={{ p: 2 }}>
            <Typography variant='h4' children={isEditMode ? `Edit User with id ${params.id}` : 'Create New User'} sx={{ mb: 2 }} />
            <Stack spacing={2}>
                <FormControl>
                    <InputLabel children='Select Role' />
                    <Select label='Select Role' sx={{ width: '300px' }} value={userType} onChange={v => setUserType(v.target.value as string)}>
                        {userTypes.map(key => <MenuItem value={key} children={userRoleType.get(key)} key={key} />)}
                    </Select>
                </FormControl>
                <TextField required label='Username' value={userName} error={(userName === '')} onChange={e => setUserName(e.target.value)} sx={{ width: '300px' }} />
                <TextField 
                    required 
                    label='Email' 
                    value={email}
                    error={!(EMAIL_REGEX.test(email))} 
                    helperText={!(EMAIL_REGEX.test(email))? 'Invalid e-mail format' : ''}
                    onChange={e => setEmail(e.target.value)} 
                    sx={{ width: '300px' }} />
                <TextField required label='Password' type='password' value={password} error={(password === '')} onChange={e => setPassword(e.target.value)} hidden sx={{ width: '300px' }} />
                {(userType === 'CUSTOMER' || userType === 'DELIVERY_PERSON') && <TextField required label='RFID Token' value={token} error={(token === '')} onChange={e => setToken(e.target.value)} sx={{ width: '300px' }} />}
                <Button 
                    variant='contained' 
                    color='success' 
                    children='Save' 
                    disabled={(userType === '') || (userName === '') || !(EMAIL_REGEX.test(email)) || (password === '') || (token === '')}
                    onClick={handleSubmit} 
                    sx={{ width: '300px' }} />
                {showSuccess && (<Alert onClose={() => setShowSuccess(false)}>
                    <>
                        {`${isEditMode ? 'Edited' : 'Created'} successfully! `}
                        {isEditMode && <Link to={`/user/${params.id}`}>Go back to User</Link>}
                        {isEditMode || <Link to='/dispatcher'>Go back to List</Link>}
                    </>
                </Alert>)}
                {showFailure && <Alert severity='error' children='There has been an Error, please check your entries and try again' onClose={() => setShowFailure(false)} />}
            </Stack>
        </Paper>
    );
}
export default CreateUser;
