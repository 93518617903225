import * as React from 'react';

import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Box from '@mui/system/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import { useSecurity } from '../service/Security';


const Signup = () => {
    const [email, setEmail] = React.useState("");
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [showError, setShowError] = React.useState(false);
    const [showSuccess, setShowSuccess] = React.useState(false);
    const security = useSecurity();

    const handleSubmit = async () => {
        const result = await security.register(email, username, password);
        setShowError(!result);
        setShowSuccess(result);        
    }

    return (
        <Paper style={{ padding: 20, height: '100%' }}>
            <Typography variant="h4">
                Login to Packride with your credentials
            </Typography>
            {showSuccess && <Alert severity="success">
                <AlertTitle>Welcome!</AlertTitle>
                Registration successful, welcome to packride
            </Alert>}
            {showError && <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                Something went wrong. Either unsupported entries or Server ded. <strong>Please try again</strong>
            </Alert>}
            <Box sx={{ width: 300, mt: '30px', mx: 'auto' }}>
                <Stack spacing={5}>
                    <TextField
                        required
                        label="Email"
                        value={email}
                        onChange={val => setEmail(val.target.value)}
                    />
                    <TextField
                        required
                        label="Username"
                        value={username}
                        onChange={val => setUsername(val.target.value)}
                    />
                    <TextField
                        required
                        label="Password"
                        type="password"
                        value={password}
                        onChange={val => setPassword(val.target.value)}
                    />
                    <Button
                        variant="contained"
                        style={{ width: 150, margin: '40px auto' }}
                        onClick={handleSubmit}>
                        Sign up
                    </Button>
                </Stack>
            </Box>
        </Paper>
    );
}
export default Signup;
