import { api } from '../service/api';

interface UserData {
    username: string,
    password: string,
}

interface registerData {
    email: string,
    username: string,
    password: string,
}

class AuthService {
    private async api_post(endpoint: string, payload: any) {
        try {
            const response = await api.post(endpoint, { ...payload });
            if (response.status === 200) {
                return response.data;
            } else {
                return undefined;
            }
        } catch (e) {
            console.log(e);
            return undefined;
        }
    }

    async login(username: string, password: string) {
        const user: UserData = {
            username: username,
            password: password,
        }

        const response = await this.api_post('auth/users/signin', user);
        if (response) {
            localStorage.setItem("userId", response);
            return true;
        } else {
            return false;
        }
    }

    async register(email: string, username: string, password: string) {
        const data: registerData = {
            email: email,
            username: username,
            password: password,
        }

        return await this.api_post('auth/users/signup', data);
    }

    async logout() {
        await this.api_post("users/signout", {});
        localStorage.removeItem("userId");
    }
}

export default new AuthService();