import * as React from 'react';

import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Box from '@mui/system/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import { useSecurity } from '../service/Security';


const Login = () => {
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [showError, setShowError] = React.useState(false);
    const security = useSecurity();

    const handleSubmit = async () => {
        setShowError(!await security.login(username, password));
    }

    const handleKeyDown = (event: { key: string; }) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    }

    return (
        <Paper style={{ padding: 20, height: '100%' }}>
            <Typography variant="h4">
                Login to Packride with your credentials
            </Typography>
            {showError && <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                Something went wrong. Either wrong credentials or Server ded. <strong>Please try again</strong>
            </Alert>}
            <Box sx={{ width: 300, mt: '30px', mx: 'auto' }}>
                <Stack spacing={5}>
                    <TextField
                        required
                        label="Username"
                        value={username}
                        onChange={val => setUsername(val.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                    <TextField
                        required
                        label="Password"
                        type="password"
                        value={password}
                        onChange={val => setPassword(val.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                    <Button
                        variant="contained"
                        style={{ width: 150, margin: '40px auto' }}
                        onClick={handleSubmit}>
                        Login
                    </Button>
                </Stack>
            </Box>
        </Paper>
    );
}
export default Login;
