import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Paper, Typography } from '@mui/material';
import { useApiService } from '../service/api';
import { OrderData, orderStatusType } from '../components/Types';

const Order = () => {
    const params = useParams();
    const api = useApiService();

    const [data, setData] = React.useState<OrderData>();

    React.useEffect(() => {
        async function fetchOrderData() {
            return await api.get(`orders/${params.id}`);
        }

        fetchOrderData().then(result => setData(result.data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    return (
        <Paper sx={{p: 2}}>
            <Typography variant='h6'>
                Order No. {params.id}
            </Typography>
            {data && (
                <>
                    <Typography>
                        This order was created at {data.createdAt} and {data.deliveredAt ? `delivered at ${data.deliveredAt}` : 'is not yet delivered'}.
                    </Typography>
                    <Typography>
                        Its status is "<strong>{orderStatusType.get(data.status)}</strong>" and its delivery person is {data.deliveryPerson ? data.deliveryPerson.username : 'not yet assigned'}.
                    </Typography>
                    <Typography>
                        Selected destination Delivery Box: <Link to={`/deliverybox/${data.destinationBox.id}`} children={data.destinationBox.address}/>
                    </Typography>
                    <Typography>
                        Customer data: <strong>{data.orderedBy.username}</strong>, {data.orderedBy.email}. 
                    </Typography>
                </>
            )}
        </Paper>
    );
}
export default Order;
