import * as React from 'react';
import { Alert, AlertTitle, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const AccessDenied = () => {
    const navigate = useNavigate();
    React.useEffect(() => {
        async function sleep() {
            return new Promise(resolve => setTimeout(resolve, 3000));
        };

        sleep().then(() => {
            navigate('/');
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Paper sx={{ p: 2 }}>
            <Typography variant='h2' children='Oops! 💩' />
            <Alert severity='error' sx={{ m: 2 }}>
                <AlertTitle children='Access Denied' />
                We are sorry, but you have no permission to view this page.
            </Alert>
            <Typography children='Contact us, if you think this is a mistake.' />
            <Typography children='Redirecting in a moment...' />
        </Paper>
    );
}
export default AccessDenied;
