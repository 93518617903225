import * as React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { OrderData, orderStatusType, UserData } from './Types';
import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';


interface OrderTableProps {
    data: OrderData[],
    isDispatcher?: boolean,
    handleDelete?: (id: string) => void,
    handleEdit?: (id: string) => void,
    pickUpOrder?: (id: string) => void,
}

export default function OrderTable(props: OrderTableProps) {
    const { isDispatcher, data, handleDelete, pickUpOrder, handleEdit } = props;

    const columns: GridColDef[] = [
        {
            field: 'id', headerName: 'ID', width: 70,
            renderCell: cellValues => <Link children={cellValues.row.id} to={`/order/${cellValues.row.id}`} />
        },
        { field: 'createdAt', headerName: 'Created at', width: 130 },
        {
            field: 'deliveredAt', headerName: 'Delivered At', width: 130,
            renderCell: cellValues => (cellValues.row.deliveredAt || <Typography children='Not yet delivered' variant='caption' color='GrayText' />)
        },
        {
            field: 'updatedAt', headerName: 'Updated At', width: 130,
            renderCell: cellValues => (cellValues.row.updatetAt || <Typography children='Not yet updated' variant='caption' color='GrayText' />)
        },
        ...isDispatcher ? [{
            field: 'orderedBy', headerName: 'Ordered By', width: 130,
            renderCell: (cellValues: { row: { orderedBy: UserData } }) => <Link children={cellValues.row.orderedBy.username} to={``} />
        }] : [],
        {
            field: 'deliveryPerson', headerName: 'Delivery Person', width: 130,
            renderCell: cellValues => (cellValues.row.deliveryPerson?.username || <Typography children='Not yet assigned' variant='caption' color='GrayText' />)
        },
        {
            field: 'destinationBox', headerName: 'Destination Box', width: 250,
            renderCell: cellValues => <Link children={cellValues.row.destinationBox.address} to={`/deliverybox/${cellValues.row.destinationBox.id}`} />
        },
        {
            field: 'status', headerName: 'Status', width: 150,
            renderCell: cellValues => orderStatusType.get(cellValues.row.status)
        },
        ...pickUpOrder ? [{
            field: 'pickUpBox', headerName: 'Pick Up Box', width: 180,
            renderCell: (cellValues: { row: { id: string, status: string } }) => <Button variant='outlined' color='success' children='Pick up this Order' disabled={cellValues.row.status !== 'WAITING'} onClick={() => pickUpOrder(cellValues.row.id)} />
        }] : [],
        ...handleEdit ? [{
            field: 'edit', headerName: 'Edit',
            renderCell: (cellValues: { row: { id: string } }) => <Button variant='contained' children='Edit' onClick={() => handleEdit(cellValues.row.id)} />
        }] : [],
        ...handleDelete ? [{
            field: 'delete', headerName: 'Delete',
            renderCell: (cellValues: { row: { id: string } }) => <Button variant='contained' color='error' children='Delete' onClick={() => handleDelete(cellValues.row.id)} />
        }] : [],
    ];

    return (
        <div style={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={data}
                columns={columns}
            />
        </div>
    );
}