import * as React from 'react';
import { Paper, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useApiService } from '../service/api';
import { DeliveryBoxData } from '../components/Types';


const DeliveryBox = () => {
    const params = useParams();
    const api = useApiService();

    const [data, setData] = React.useState<DeliveryBoxData>();

    React.useEffect(() => {
        async function fetchBoxData() {
            return await api.get(`deliveryboxes/${params.id}`);
        }

        fetchBoxData().then(result => setData(result.data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    return (
        <Paper sx={{p: 2}}>
            <Typography variant='h6'>
                Delivery Box No. {params.id}
            </Typography>
            {data && (
                <Typography>
                    Its address is <strong>{data.address}</strong> and it's currently <strong>{data.empty ? 'empty' : 'full'}</strong>.
                </Typography>
            )}
        </Paper>
    );
}
export default DeliveryBox;
