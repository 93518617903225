import * as React from 'react';
import { Paper, Typography } from '@mui/material';
import { useApiService } from '../service/api';
import OrderTable from '../components/OrderTable';
import { OrderData, UserData } from '../components/Types';

export interface CostumerData {
    costumerId?: string,
    name?: string,
    email?: string,
    orders?: OrderData[],
}

const Costumer = () => {
    const api = useApiService();

    const [customer, setCostumer] = React.useState<CostumerData>();
    const [userData, setUserData] = React.useState<UserData>();
    const [orderData, setOrderData] = React.useState<OrderData[]>([]);

    // useEffect to init costumer obj
    //      retrieve costumerid from ls
    //      retrieve other data from api with id
    // display all data in comp
    React.useEffect(() => {
        async function fetchUserData() {
            return await api.get(`auth/users/${_costumerId}`);
        }
        async function fetchOrderData() {
            return await api.get('api/orders');
        }

        const _costumerId = localStorage.getItem("userId");
        setCostumer({ costumerId: _costumerId || undefined });
        fetchUserData().then(res => setUserData(res.data));

        fetchOrderData().then(res => setOrderData(res.data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (!userData) {
            // something went horribly wrong
            return;
        }
        setCostumer(prev => ({
            ...prev,
            name: userData.username,
            email: userData.email,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData]);

    React.useEffect(() => {
        orderData && setCostumer(prev => ({
            ...prev,
            orders: [...orderData],
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderData])

    return (
        customer ? (
            <Paper sx={{ p: 2 }}>
                <Typography>Hi {customer.name}!</Typography>
                <Typography>Your email: {customer.email}.</Typography>
                <Typography>This is your customer-id: {customer.costumerId}.</Typography>
                <Typography variant='h6' sx={{ mt: 2 }}>Below, all your orders are listed</Typography>
                <OrderTable data={orderData} />
            </Paper>
        ) : (
            <h3>Error</h3>
        )
    );
}
export default Costumer;
