import * as React from 'react';
import { Link } from 'react-router-dom';
import { Paper, Typography } from '@mui/material';


const Home = () => {
    return (
        <Paper sx={{ p: 2 }}>
            <Typography variant="h2">Welcome to packri.de</Typography>
            <Typography>You can <Link to="/login">log in</Link> here.</Typography>
            <Typography>You can also <Link to="/signup">sign up</Link>.</Typography>
            <Typography><Link to="/customeroverview">Customer Center</Link></Typography>
            <Typography><Link to="/deliverer">Deliverer Overview</Link></Typography>
            <Typography><Link to="/dispatcher">Dispatcher Dashboard</Link></Typography>
        </Paper>
    );
}
export default Home;
