import * as React from 'react';
import { Button } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { UserData } from './Types';
import { Link } from 'react-router-dom';


interface UserTableProps {
    data: UserData[],
    handleDelete?: (id: string) => void,
    handleEdit?: (id: string) => void,
}

export default function UserTable({ data, handleDelete, handleEdit }: UserTableProps) {
    const columns: GridColDef[] = [
        {
            field: 'id', headerName: 'ID', width: 250,
            renderCell: cellValues => <Link children={cellValues.row.id} to={`/user/${cellValues.row.id}`} />
        },
        { field: 'username', headerName: 'User Name', width: 200 },
        { field: 'email', headerName: 'Email Address', width: 300 },
        ...handleEdit ? [{
            field: 'edit', headerName: 'Edit',
            renderCell: (cellValues: { row: { id: string } }) => <Button variant='contained' children='Edit' onClick={() => handleEdit(cellValues.row.id)} />
        }] : [],
        ...handleDelete ? [{
            field: 'delete', headerName: 'Delete',
            renderCell: (cellValues: { row: { id: string } }) => <Button variant='contained' color='error' children='Delete' onClick={() => handleDelete(cellValues.row.id)} />
        }] : []
    ];

    return (
        <div style={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={data}
                columns={columns}
            />
        </div>
    );
}