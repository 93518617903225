import * as React from 'react';
import { useApiService } from '../service/api';

const Test = () => {
    const api = useApiService();
    const [data, setData] = React.useState({});

    React.useEffect(() => {
        const fetchData = async () => {
            const response = await api.get('roletypes');
            return response;
        }
        fetchData().then(res => {setData(res.data)});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <>
            <p>You can only see this page when you are logged in.</p>
            <div>
                {JSON.stringify(data)}
            </div>
        </>
    );
}
export default Test;
