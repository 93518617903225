import * as React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { DeliveryBoxData } from './Types';
import { Alert, Button } from '@mui/material';
import { Link } from 'react-router-dom';


interface BoxTableProps {
    data: DeliveryBoxData[],
    handleDelete?: (id: string) => void,
    handleEdit?: (id: string) => void,
}

export default function BoxTable({ data, handleDelete, handleEdit }: BoxTableProps) {
    const columns: GridColDef[] = [
        {
            field: 'id', headerName: 'ID', width: 250,
            renderCell: cellValues => <Link children={cellValues.row.id} to={`/deliverybox/${cellValues.row.id}`} />
        },
        { field: 'address', headerName: 'Box Address', width: 400 },
        {
            field: 'empty', headerName: 'Box empty?', width: 150,
            renderCell: cellValues => (cellValues.row.empty ? (
                    <Alert severity='success' children='Empty' sx={{ py: 0}} />
                ) : (
                    <Alert severity='error' children='Full' sx={{ py: 0 }} />
                )
            )
        },
        ...handleEdit ? [{
            field: 'edit', headerName: 'Edit',
            renderCell: (cellValues: { row: { id: string } }) => <Button variant='contained' children='Edit' onClick={() => handleEdit(cellValues.row.id)} />
        }] : [],
        ...handleDelete ? [{
            field: 'delete', headerName: 'Delete',
            renderCell: (cellValues: { row: { id: string } }) => <Button variant='contained' color='error' children='Delete' onClick={() => handleDelete(cellValues.row.id)} />
        }] : []
    ];

    return (
        <div style={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={data}
                columns={columns}
            />
        </div>
    );
}